
// Default .button class
.button {
    @extend %button;
    @extend %uppercase-text;
    display: inline-block;
    // padding: ($block-padding * 0.75) ($block-padding * 2);

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
