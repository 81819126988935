
// Content styles
.block-text,
.biography {
	line-height: 1.7;

	h3 {
		@extend %uppercase-text;
		color: $color-quinary;
		font-size: $h4 - 0.2;
		font-weight: $font-bold;
	}

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			color: $color-text;
			font-family: $font-family-serif;
			font-size: $h5;

			@media #{$mobileXL} {
				font-size: $h4;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}

.block-text {

	blockquote {
		margin-left: 0;
		border-left: 8px solid rgba($color-septenary, .15);
		font-style: italic;
		margin-bottom: $block-margin;
		padding: $site-padding 0 $site-padding ($site-padding * 1.5);

		p {
			margin: 0;
		}

		cite {
			margin-top: $block-margin - 1rem;
			display: block;
			font-weight: $font-bold;
		}
	}
}
